$( document ).ready(function() {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 700 && $(window).width() > 1023){  
            $(".nav-sticky").fadeIn("slow");
        }
        else{
            $(".nav-sticky").fadeOut("slow");
        }
    });

    $(".logo").on("click", function () {
            $("html, body").animate({
                scrollTop: $("#intro").offset().top
            }, 1000);
            return false;
    });

    $(window).scroll(function() {
        if ($(this).scrollTop() > 700 && $(window).width() < 1024){  
            $(".nav-responsive").fadeIn("slow");
        }
        else{
            $(".nav-responsive").fadeOut("slow");
       }
    });

    (function($) {
        $.fn.clickToggle = function(func1, func2) {
            var funcs = [func1, func2];
            this.data('toggleclicked', 0);
            this.click(function() {
                var data = $(this).data();
                var tc = data.toggleclicked;
                $.proxy(funcs[tc], this)();
                data.toggleclicked = (tc + 1) % 2;
            });
            return this;
        };
    }(jQuery));

    $(".menu-toggle").clickToggle(function() {
        $(this).addClass("rotate");
        $(".menu-responsive").fadeIn("slow");
        $("body").css("overflow", "hidden");
    }, function() {
        $(this).removeClass("rotate");
        $(".menu-responsive").fadeOut("slow");
        $("body").css("overflow", "visible");
    });

    $(".menu-logo").on("click", function () {
            $("html, body").animate({
                scrollTop: $("#intro").offset().top
            }, 1000);
            $(".menu-toggle").removeClass("rotate");
            $(".menu-responsive").fadeOut("slow");
            $("body").css("overflow", "visible");
    });

    $(".menu-about").on("click", function () {
            $("html, body").animate({
                scrollTop: $("#intro").offset().top
            }, 1000);
            $(".menu-toggle").removeClass("rotate");
            $(".menu-responsive").fadeOut("slow");
            $("body").css("overflow", "visible");
    });

    $(".menu-tmw").on("click", function () {
            $("html, body").animate({
                scrollTop: $("#tmw-base").offset().top
            }, 1000);
            $(".menu-toggle").removeClass("rotate");
            $(".menu-responsive").fadeOut("slow");
            $("body").css("overflow", "visible");
    });

    $(".menu-som").on("click", function () {
            $("html, body").animate({
                scrollTop: $("#som-base").offset().top
            }, 1000);
            $(".menu-toggle").removeClass("rotate");
            $(".menu-responsive").fadeOut("slow");
            $("body").css("overflow", "visible");
    });

    $(".menu-contact").on("click", function () {
            $("html, body").animate({
                scrollTop: $("#contact").offset().top
            }, 1000);
            $(".menu-toggle").removeClass("rotate");
            $(".menu-responsive").fadeOut("slow");
            $("body").css("overflow", "visible");
    });

    $(".nav_item").on("click", function () {
            var section_id = $(this).find('a').attr('href');
            $("html, body").animate({
                scrollTop: $(section_id).offset().top
            }, 1000);
            return false;
    });

    ;(function($, win) {
        $.fn.inViewport = function(cb) {
            return this.each(function(i,el){
                function visPx(){
                    var H = $(this).height(),
                        r = el.getBoundingClientRect(), t=r.top, b=r.bottom;
                    return cb.call(el, Math.max(0, t>0? H-t : (b<H?b:H)));  
                } visPx();
                $(win).on("resize scroll", visPx);
            });
        };
    }(jQuery, window));

    $("#intro").inViewport(function(px){
        if(px) $("#intro .polygon-big").addClass("go");
        if(px) $("#intro .polygon-mobile").addClass("go");
        if(px) $("#intro .polygon-1").addClass("go");
        if(px) $("#intro .polygon-2").addClass("go");
        if(px) $("#intro .logo-big").addClass("go");
        if(px) $("#intro .intro-text").addClass("go");
        if(px) $("#social").addClass("go");
        if(px) $("nav").addClass("go");
    });

    $("#tmw-base").inViewport(function(px){
        if(px) $("#tmw-base .comet-speed").addClass("go");
        if(px) $("#tmw-base .comet-tech").addClass("go");
        if(px) $("#tmw-base .comet-nature").addClass("go");
        if(px) $("#tmw-base .comet-slow").addClass("go");
        if(px) $("#tmw-base .comet-lvlup").addClass("go");
        if(px) $("#tmw-base .tmw-title").addClass("go");
        if(px) $("#tmw-base .section-info").addClass("go");
        if(px) $("#tmw-base .tmw-buttons-base").addClass("go");
        if(px) $("#tmw-base .release-date").addClass("go");
        if(px) $("#tmw-base .video").addClass("go");
    });

    $("#som-base").inViewport(function(px){
        if(px) $("#som-base .shard1").addClass("go");
        if(px) $("#som-base .shard2").addClass("go");
        if(px) $("#som-base .shard3").addClass("go");
        if(px) $("#som-base .shard4").addClass("go");
        if(px) $("#som-base .shard5").addClass("go");
        if(px) $("#som-base .som-logo").addClass("go");
        if(px) $("#som-base .section-info").addClass("go");
        if(px) $("#som-base .som-buttons-base").addClass("go");
        if(px) $("#som-base .release-date").addClass("go");
        if(px) $("#som-base .video").addClass("go");
    });

    $("#contact").inViewport(function(px){
        if(px) $("#contact .contact-info").addClass("go");
        if(px) $("#contact .contact-form").addClass("go");
    });

    $(window).on('scroll', function() {
        $('.polygon-big').css('margin-top', $(window).scrollTop() * -.2);
        $('.logo-big').css('margin-top', $(window).scrollTop() * -.2);
        $('.polygon-1').css('margin-top', $(window).scrollTop() * -.25);
        $('.polygon-2').css('margin-top', $(window).scrollTop() * -.3);
        $('.intro-text').css('margin-top', $(window).scrollTop() * -.4);
        $('#intro .stars').css('margin-top', $(window).scrollTop() * -.1);
        $('#tmw-base .stars').css('margin-top', $(window).scrollTop() * -.15);
        $('.shard1').css('margin-top', $(window).scrollTop() * -.1);
        $('.shard2').css('margin-top', $(window).scrollTop() * -.05);
        $('.shard3').css('margin-top', $(window).scrollTop() * -.15);
        $('.shard4').css('margin-top', $(window).scrollTop() * -.3);
        $('.shard5').css('margin-top', $(window).scrollTop() * -.15);
    });

    var shield=$(".shield");
    var shieldCenter=[shield.offset().left+shield.width()/2, shield.offset().top+shield.height()/2];

    $(document).mousemove(function(e){    
           
        var angle = Math.atan2(e.pageX- shieldCenter[0],- (e.pageY- shieldCenter[1]) )*(180/Math.PI);     
        
        shield.css({ '-webkit-transform': 'rotate(' + angle + 'deg)'});    
        shield.css({ '-moz-transform': 'rotate(' + angle + 'deg)'});
        shield.css({ 'transform': 'rotate(' + angle + 'deg)'});
    });

    $('body').scrollspy({ 
        target: '#main-nav',
        offset: 500
    });

    $('.play-btn').on('click', function(ev) {
        $(this).parent().delay(300).fadeOut();
        $(this).parent().parent().find("#video")[0].src += "&autoplay=1";
        ev.preventDefault();
    });
});